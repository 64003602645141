<template>
  <CustomModal v-model="showReportConfigModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{reportConfig != null && reportConfig._id != null ? $t('modal.titleEditForm') : $t('modal.titleCreateForm')}}</template>
    <div class="form" v-if="reportConfig != null && groupsById != null">
      <div class="row" >
        <div class="label" >{{ $t('modal.labelName') }}:</div>
        <div class="value"><input disabled="true" ref="reportConfigName" v-model="reportConfig.name" type="text"/></div>
      </div>
      <div class="row" v-if="!reportConfig.inheritsRights">
        <div class="label">{{ $t('modal.labelRights') }}:</div>
        <div class="value">
          <table>
            <thead><th>{{ $t('modal.labelUserOrGroup') }}</th><th>{{ $t('modal.labelCollectData') }}</th><th>{{ $t('modal.labelEditConfig') }}</th><th class="removeColumn"></th></thead>
            <tbody>
              <tr v-for="ar in reportConfig.accessRights" :key="ar.userGroup">
                <td>{{!ar.isUser ? getGroupName(ar.userGroup) : ar.user.firstname + ' ' + ar.user.lastname}}</td>
                <td><input type="checkbox" value="READ" data-cy='reportConfig-permission-read'  v-model="ar.read" @click="handleRightChange(ar, 'READ')"></td>
                <td><input type="checkbox" value="WRITE" data-cy='reportConfig-permission-write'  v-model="ar.write" @click="handleRightChange(ar, 'WRITE')"></td>
                <td class="removeColumn"><span class="removeLink" @click="removeGroup(ar)"><i class='fas fa-trash'/></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="!reportConfig.inheritsRights">
        <div class="label"></div>
        <div class="value">
          {{ $t('modal.labelAddUserGroup') }}:<br/>
          <select @change="addGroup" v-model="selectedGroup" data-cy="reportConfig-add-usergroup">
            <option value="-1" >{{ $t('general.pleaseChoose') }}</option>
            <option :value="g._id" v-for="g in proposedGroups" :key="g._id">{{g.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';

export default {
  name: 'CreateOrEditReportConfigModal',
  props: ['reportConfigToEdit'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showReportConfigModal: false,
      reportConfig: null,
      groups: [],
      groupsById: null,
      showGroupOptions: true,
      selectedGroup: '-1',
    };
  },
  watch: {
    async reportConfigToEdit(newVal) {
      this.reportConfig = newVal;
      this.reportConfig.accessRights = this.reportConfig.accessRights.map((ar) => ({
        ...ar,
        isUser: (ar.user != null),
        read: ar.type === 'READ',
        write: ar.type === 'WRITE',
      }));

      this.reportConfig.accessRights.forEach((entry) => {
        const e = entry.user;
        if (e) {
          /* if (e != null && e.firstname !== 'admin') { */
          if (e.firstname != null) e.firstname = CryptoHelper.decrypt(e.firstname).toString('utf-8');
          if (e.lastname != null) e.lastname = CryptoHelper.decrypt(e.lastname).toString('utf-8');
          if (e.email != null) e.email = CryptoHelper.decrypt(e.email).toString('utf-8');
        }
        // }
      });

      // console.log('WATCH!', this.reportConfig);
    },
  },
  mounted() {
    this.reportConfig = this.reportConfigToEdit;
    this.fetchGroups();
  },
  computed: {
    proposedGroups() {
      const existingIds = {};
      this.reportConfig.accessRights.forEach((g) => { existingIds[g.userGroup] = true; });
      const pgs = this.groups.filter((g) => !existingIds[g._id]);
      return pgs;
    },
  },
  methods: {
    handleRightChange(accessRight, type) {
      const ar = accessRight;
      ar.type = type;
      ar.read = type === 'READ';
      ar.write = type === 'WRITE';
    },
    getGroupName(g) {
      if (this.groupsById != null && this.groupsById[g] != null) {
        return this.groupsById[g].name;
      }
      return 'unknown';
    },
    async show() {
      await this.fetchGroups();
      this.showReportConfigModal = true;
    },
    hide() {
      this.showReportConfigModal = false;
    },
    async confirm() {
      // encrypt and send user
      try {
        this.reportConfig.name = CryptoHelper.encrypt(this.reportConfig.name);
        this.reportConfig.accessRights = this.reportConfig.accessRights.filter((ar) => (ar.write || ar.read));
        this.reportConfig.accessRights = this.reportConfig.accessRights.map((ar) => ({
          reportConfig: this.reportConfig._id,
          userGroup: ar.isUser ? null : ar.userGroup,
          user: ar.isUser ? ar.user._id : null,
          type: ar.type,
        }));
        await ServiceHelper.sendDataToBackend(`reportConfig/${this.reportConfig._id}`, this.reportConfig, 'PUT');
        this.$emit('refresh');
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    cancel() {
      this.hide();
    },
    async fetchGroups() {
      const data = await ServiceHelper.loadDataFromBackend('userGroups');
      const groupsById = {};
      data.payload.forEach((entry) => {
        if (entry.name != null) {
          const e = entry;
          e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          groupsById[e._id] = e;
        }
      });
      this.groups = data.payload;
      this.groupsById = groupsById;
      this.autoFocus();
      return null;
    },
    autoFocus() {
      if (!this.$refs.reportConfigName) {
        setTimeout(this.autoFocus, 200);
      } else {
        this.$refs.reportConfigName.focus();
      }
    },
    addGroup() {
      if (this.selectedGroup !== '-1') {
        this.reportConfig.accessRights.push({
          userGroup: this.selectedGroup,
          type: 'READ',
          read: true,
        });
        this.selectedGroup = '-1';
      }
    },
    removeGroup(group) {
      this.reportConfig.accessRights = this.reportConfig.accessRights.filter((ar) => ar.userGroup !== group.userGroup);
    },
  },
};
</script>

<style scoped>
::v-deep(.value table) {
  width: 100%;
}

::v-deep(.label) {
  vertical-align: top;
}

::v-deep(.value th) {
  background: lightgray;
}

::v-deep(.value td) {
  border-bottom: 1px solid lightgray;
}

::v-deep(.value select) {
  width: 100%;
}

::v-deep(.removeLink) {
  font-size: 12px;
  cursor: pointer;
  color: gray;
}

</style>
